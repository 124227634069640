import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import visa from '../images/VISA.png';
import explore from '../images/explore.png';
import travel from '../images/travel.jpg';
import p1 from '../images/slideshow/andaman.png';
import p2 from '../images/slideshow/beaches_slideshow.png';
import p3 from '../images/slideshow/dubai.png';
import p4 from '../images/slideshow/ticketToparadise.png';
import p5 from '../images/slideshow/westandout.png';
import p6 from '../images/Photos/Dubai/Yellow and Black Travel Collection Video.gif'
const slideImages = [
  {
    img: p1,
    caption: 'Slide 1',
  },
  {
    img: p2,
    caption: 'Slide 1',
  },
  {
    img: p3,
    caption: 'Slide 1',
  },
  {
    img: p4,
    caption: 'Slide 1',
  }
  ,
  {
    img: p5,
    caption: 'Slide 2',
  },
  {
    img: p6,
    caption: 'Slide 2',
  }
];

export default function Slidingimg() {
  return (
    <>
      <Slide duration={3000} transitionDuration={100}>
        {slideImages.map((slideImage, index) => (
          <div key={index} className="h-[13rem] mt-24 lg:mt-20 lg:h-[42rem] aspect-w-16 aspect-h-9 relative" id='home'>
            <img src={slideImage.img} className="w-full h-full object-fill " />
            {/* <div className="absolute inset-9 flex flex-col items-center justify-center md:flex-row md:justify-between p-4 md:p-0">
              <div className="w-full md:w-1/2 text-center md:text-left mt-36 lg:mt-72 lg:ml-80 lg:w-96">
                <a
                  href="#contactUs"
                  className="px-6 py-3 bg-[#294ccc] text-white font-medium rounded-full hover:bg-[#c09858] transition duration-200"
                >
                  Know More
                </a>
              </div>
            </div> */}
          </div>
        ))}
      </Slide>
    </>
  );
}
